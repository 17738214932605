import './App.css';
import Study from './Study';
import Results from './Results';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

function App() {
  return (
    <Router>

      <Switch>
          <Route path="/results">
            <Results /> 
          </Route>
          <Route path="/">
            <Study />
          </Route>
        </Switch>

    </Router>
    // <p>hey !</p>
  );
}

export default App;
