import React, {Component} from 'react';
import axios from 'axios';
import dictee from './dictee.mp3';
import load_img from './load_img.png';


const THXMESSAGE="Votre réponse a bien été enregistrée. Merci d'avoir participé à cette étude ! Afin qu'elle soit le plus pertinente possible, merci de ne pas y répondre vous-même de nouveau."
let replacementText = {
    sans_autocorrecteur: `La casserole rebondit sur la plaque électrique. Martin verse l'eau boullante dans un
    verre épais. L'eau se trouble de café solulbe. Les carreaux se sont embués, il passe le poing
    dessus et laisse une longue trace de crase. Il a l'air de faire beau aujourd’hui. La fenêtre
    coince, il s'y reprend à plusieurs fois avant qu'elle ne souvre dans un grand bruit de vitres
    secouées. Un de ces jours, elles vont se brisser, c'est sûr. Il faudrait raboter les montants.
    Martin se penche par-dessus l'évier pour regrader le ciel. Oui, il fait baeu, le petit carré de
    ciel qui se découpe en haut des immeubles de la cour intérieure est tout bleu.`,
    avec_autocorrecteur: `La casserole rebondit sur la plaque <span class="textError">électirque</span>. Martin verse l'eau boullante <span class="textError">dansun</span>
    verre épais. L'eau se trouble de café solulbe. Les <span class="textError">carrreaux</span> se sont embués, il passe le poing
    dessus et laisse une longue trace de crase. Il a l'air de faire beau aujourd’hui. La <span class="textError">fenêrte</span>
    coince, il <span class="textError">sy</span> reprend à plusieurs fois avant qu'elle ne souvre dans un grand bruit de vitres
    secouées. Un de ces <span class="textError">jour</span>, elles vont se brisser, c'est sûr. Il faudrait raboter les montants.
    Martin se penche par-dessus l'évier pour regrader le ciel. Oui, il fait baeu, le petit carré de
    ciel qui se découpe en haut des immeubles de la <span class="textError">cours</span> intérieure est tout bleu.`,
};

function initializeAudio(){
    let audio = new Audio(dictee);
    return audio;
}
async function pickVersion(){
    let response = await axios
        .get('https://all-back.smythe.fr/study-log')
        ;
    
    let amount_correc = 0;
    let amount_nocorrec = 0;
    let target = null;
    response.data.forEach(function(item){
        if(item.version === "avec_autocorrecteur") amount_correc ++;
        if(item.version === "sans_autocorrecteur") amount_nocorrec ++;
    })
    
    if(amount_correc === amount_nocorrec){
        target = Math.random()<0.5 ? "avec_autocorrecteur" : "sans_autocorrecteur";
    }else if(amount_correc>amount_nocorrec){
        target = "sans_autocorrecteur";
    }else{
        target = "avec_autocorrecteur";
    }
    return await target;

}
export default class Study extends Component{
    constructor(props){
        super(props);
        this.state = {  
            confirmMessage: "Le fichier audio ne sera joué qu'une seule fois, et dure environ 4 minutes. Êtes-vous prêt ?",
            tooltip: '',
            playing: false,
            played: false,
            audio: initializeAudio(),
            version: null,
            age: "Choisir",
            sexe: "Choisir",
            niveau: "Choisir",
            emptyAge: false,
            emptySexe: false,
            emptyNiveau: false,
        }
        this.play = this.play.bind(this);
        this.swapText = this.swapText.bind(this);
        this.handleAge = this.handleAge.bind(this);
        this.handleSex = this.handleSex.bind(this);
        this.handleLevel = this.handleLevel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.playBtn = React.createRef();
        this.writable = React.createRef();
    }
    async play(){
        if(window.confirm(this.state.confirmMessage)){
            
            let version = await pickVersion();
            this.setState({
                playing: true,
                tooltip:"Le fichier audio est en train d'être joué...",
                version: await version,
            })
            this.state.audio.play();
        }
    }
    swapText(){
        let 
        // contentZone = document.getElementById("content"),
            txtArea = document.getElementById("writable"),
            filterBoard = document.getElementById("filterBoard"),
            sendBtn = document.getElementById("send")
        ;
        filterBoard.style.display = "flex";
        txtArea.style.filter = "blur(3px)";
        txtArea.contentEditable = false;
        txtArea.innerHTML = replacementText.sans_autocorrecteur;
        this.setState({
            playing:false,
            played:true,
            tooltip:"La dictée est terminée."
        })
        let version = this.state.version;
        setTimeout(function(){
            filterBoard.style.display = "none";
            sendBtn.style.display = "flex";
            txtArea.style.filter = "blur(0px)";
            txtArea.innerHTML = replacementText[version];
            
            txtArea.contentEditable = true;
        }, 1000)
    }
    componentDidMount(){
        let {audio} = this.state;
        let swapText = this.swapText;
        audio.onended = function() {
            setTimeout(function(){
                swapText();
            }, 1000)
        };
    }
    handleAge(e){
        this.setState({
            age: e.target.value
        })
    }
    handleSex(e){
        this.setState({
            sexe: e.target.value
        })
    }
    handleLevel(e){
        this.setState({
            niveau: e.target.value
        })
    }
    async handleSubmit(){
        const {age, sexe, niveau, version} = this.state;
        const texte =  this.writable.current.innerHTML;
        let ageCheck = true;
        let sexeCheck = true;
        let niveauCheck = true; 
        if(age==="Choisir"){ageCheck = false};
        if(sexe==="Choisir"){sexeCheck = false};
        if(niveau==="Choisir"){niveauCheck = false};

        if(ageCheck && sexeCheck && niveauCheck){
            var stripedHtml = texte.replace(/<[^>]+>/g, '');      
            let data = {
                age: age,
                sexe: sexe,
                niveau: niveau,
                version: version,
                texte: stripedHtml,
            };
            let response = await axios.post('https://all-back.smythe.fr/study-log/new', data);
            alert(THXMESSAGE);
            document.location.reload();
        }else{
            this.setState({
                emptyAge: !ageCheck,
                emptySexe: !sexeCheck,
                emptyNiveau: !niveauCheck,
                tooltip: "Veuillez remplir les champs obligatoires."
            })
        }
    }
    render(){
        const {playing, tooltip, played, emptyAge, emptySexe, emptyNiveau} = this.state;
        return(
            <main>
                <h1>étude - épreuve dictée</h1>
                <hr />
                <div id="header">
                    <h2>Nous avons besoin de quelques informations sur votre profil (aucun cookie ne sera enregistré)</h2>
                    <div id="questions">
                        <p>Section d'âge en années</p>
                        <select className={emptyAge? "inputError" : undefined} onChange={(e)=>this.handleAge(e)}>
                            <option value="Choisir">{"Choisir"}</option>
                            <option value="< 18">{"< 18"}</option>
                            <option value="18 - 25">{"18 - 25"}</option>
                            <option value="26 - 35">{"26 - 35"}</option>
                            <option value="36 - 50">{"36 - 50"}</option>
                            <option value="50 <">{"50 <"}</option>
                        </select>
                        <p>Sexe</p>
                        <select className={emptySexe ? "inputError" : undefined} onChange={(e)=>this.handleSex(e)}>
                            <option value="Choisir">{"Choisir"}</option>
                            <option value="Homme">{"Homme"}</option>
                            <option value="Femme">{"Femme"}</option>
                        </select>
                        <p>Niveau d'éducation</p>
                        <select className={emptyNiveau ? "inputError" : undefined} onChange={(e)=>this.handleLevel(e)}>
                            <option value="Choisir">{"Choisir"}</option>
                            <option value="Sans diplôme">{"Sans diplôme"}</option>
                            <option value="Brevet des collèges">{"Brevet des collèges"}</option>
                            <option value="Baccalauréat ou équivalent">{"Baccalauréat ou équivalent"}</option>
                            <option value="Bac +3">{"Bac +3"}</option>
                            <option value="Bac +5 et plus">{"Bac +5 et plus"}</option>
                        </select>
                    </div>
                    <h2>Écrivez le texte qui vous sera dicté dans la zone ci-dessous, sans retour à la ligne.</h2>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <h3>Cliquez ici pour jouer le fichier audio quand vous serez prêts : </h3>
                        <button disabled={playing||played} ref={this.playBtn} onClick={()=>this.play()}>Play</button>
                    </div>
                </div>
                <div id="content">
                    <div contentEditable="true" spellCheck="false" id="writable" ref={this.writable}></div>
                    <div id="filterBoard">
                        <img src={load_img} id="load" alt="loading pictogram"/>
                        <p id="tooltip">Processing your text...</p>
                    </div>
                </div>
                <p id="tooltip">{tooltip}</p>   
                <div>
                    <button id="send" onClick={(e)=>this.handleSubmit(e)}>Confirmer l'envoi du texte</button>
                </div>
            </main>
        )
    }
}
