import React, {Component} from 'react';
import axios from 'axios';
import ReactJson from "react-json-view";

async function getResults(){
    let response = await axios
        .get('https://all-back.smythe.fr/study-log')
    return response['data'];
}
const password="loun@f@ber";

export default class Results extends Component{
    constructor(props){
        super(props);
        this.state = {
            results: null,
            logged: false,
        }
    }
    async componentDidMount(){
        let results = await getResults();
        this.setState({results:results});
    }
    handleInputChange(e){
        if(e.target.value === password){
            this.setState({logged:true})
        }
    }
    render(){
        let {results, logged} = this.state;
        if(results){
            results = results.sort(function(a, b){
                return(a.version<b.version ? 1 : -1);
            })
        }
        return(
            <main>
                {logged && <>
                    <h2>{results.length} réponse(s)</h2><br/><br/>
                    <ReactJson
                        src={results}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        displayArrayKey={false}
                        enableClipboard={false}
                    />
                </>}
                {!logged && <input type="password" placeholder="enter your password" onChange={(e)=>this.handleInputChange(e)}/>}
            </main>
        )
    }
}
